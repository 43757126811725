/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

* {
    box-sizing: border-box;
}

html { overflow: hidden; font-family: system-ui, sans-serif; }
body { overflow: hidden ; }

.animated-button {
  border: 0;
  background: linear-gradient(-30deg, #0b1b3d 50%, #08142b 50%);
  padding: 12px 20px;
  margin-top: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: 'Submit Color';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
  background: linear-gradient(-30deg, blue 0%, navy 100%);
}

.animated-button:hover::before {
  opacity: 1;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 4px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 20, 43, 0)), to(#ff33cc));
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #ff33cc);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 4px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 20, 43, 0)), to(#ff33cc));
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #ff33cc);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 4px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 20, 43, 0)), to(#ff33cc));
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #ff33cc);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 4px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 20, 43, 0)), to(#ff33cc));
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #ff33cc);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button-1::before {
  content: 'Play Again'!important;
}


/*

   DISPLAY
   Docs: http://tachyons.io/docs/layout/display

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    t-row          = table-row
    t-columm       = table-column
    t-column-group = table-column-group

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.dn {              display: none; }
.di {              display: inline; }
.db {              display: block; }
.dib {             display: inline-block; }
.dit {             display: inline-table; }
.dt {              display: table; }
.dtc {             display: table-cell; }
.dt-row {          display: table-row; }
.dt-row-group {    display: table-row-group; }
.dt-column {       display: table-column; }
.dt-column-group { display: table-column-group; }
.w-100 { width: 100%; }
.w-50 { width: 50%; }
.w-25 { width: 25%; }

/*
  This will set table to full width and then
  all cells will be equal width
*/
.dt--fixed {
  table-layout: fixed;
  width: 100%;
}
  .flex {
      display: flex;
  }

@media screen and (min-width: 30em) {
  .dn-ns {              display: none; }
  .di-ns {              display: inline; }
  .db-ns {              display: block; }
  .dib-ns {             display: inline-block; }
  .dit-ns {             display: inline-table; }
  .dt-ns {              display: table; }
  .dtc-ns {             display: table-cell; }
  .dt-row-ns {          display: table-row; }
  .dt-row-group-ns {    display: table-row-group; }
  .dt-column-ns {       display: table-column; }
  .dt-column-group-ns { display: table-column-group; }

  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%;
  }
  .flex-ns {
      display: flex;
  }
.w-100-ns { width: 100%; }
.w-50-ns { width: 50%; }
.w-25-ns { width: 25%; }
}

@media screen and (min-width: 48em) {
  .dn-m {              display: none; }
  .di-m {              display: inline; }
  .db-m {              display: block; }
  .dib-m {             display: inline-block; }
  .dit-m {             display: inline-table; }
  .dt-m {              display: table; }
  .dtc-m {             display: table-cell; }
  .dt-row-m {          display: table-row; }
  .dt-row-group-m {    display: table-row-group; }
  .dt-column-m {       display: table-column; }
  .dt-column-group-m { display: table-column-group; }

  .dt--fixed-m {
    table-layout: fixed;
    width: 100%;
  }
  .flex-m {
      display: flex;
  }
}

@media screen and (min-width: 64em) {
  .dn-l {              display: none; }
  .di-l {              display: inline; }
  .db-l {              display: block; }
  .dib-l {             display: inline-block; }
  .dit-l {             display: inline-table; }
  .dt-l {              display: table; }
  .dtc-l {             display: table-cell; }
  .dt-row-l {          display: table-row; }
  .dt-row-group-l {    display: table-row-group; }
  .dt-column-l {       display: table-column; }
  .dt-column-group-l { display: table-column-group; }

  .dt--fixed-l {
    table-layout: fixed;
    width: 100%;
  }
  .flex-l {
      display: flex;
  }
}
